.menuLink {
    font-family: "Roboto Mono", monospace;
    font-style: normal;
    font-size: 20px;
    line-height: 26px;
    background-color: transparent;
    border-radius: 0;
    color: #25303E;
}

.menuLink:hover {
    text-decoration: underline;
    background-color: transparent;
}

.subMenuLink {
    font-family: "Roboto Mono", monospace;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-transform: uppercase;
}