.dataTableContainer .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: rgba(2,53,83,0.6);
}

.dataTableContainer .MuiTableRow-root.Mui-selected {
    background-color: #023553;
}

.dataTableContainer .MuiTableRow-root.Mui-selected .MuiTableCell-body,
.dataTableContainer .MuiTableRow-root.MuiTableRow-hover:hover .MuiTableCell-body {
    color: #ffffff;
}

.dataTableHeader {
    background-color: #989898;
}

.dataTableContainer .MuiTableRow-head .MuiTableCell-head,
.dataTableContainer .MuiTableRow-head .MuiTableCell-head .MuiTableSortLabel-root,
.dataTableContainer .MuiTableRow-head .MuiTableCell-head .MuiTableSortLabel-root .MuiTableSortLabel-icon {
    color: #ffffff;
}

.dataTableContainer .MuiTablePagination-root p.MuiTablePagination-selectLabel,
.dataTableContainer .MuiTablePagination-root p.MuiTablePagination-displayedRows
{
    margin: auto 0 auto;
    font-size: 14px;
    font-weight: 400;
    line-height: 29px;
}
