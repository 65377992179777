/* General header styling  */

.headerPlaceholder {
  display: flex;
  flex-direction: row;
  height: 76px;
  width: 100%;
  background-color: #ffffff;
  color: #004cff;
  position: relative;
  z-index: 10;
}

@media (max-width: 950px) {
  .headerPlaceholder {
    height: 50px;
    transition: height 0.2s ease;
    position: fixed;
    top: 0;
  }
}

@media (min-width: 950px) {
  .headerPlaceholder {
    transition: height 0.2s ease;
  }
}

/* General content styling  */

.contentPlaceholder {
  min-height: calc(100vh - 76px - 120px);
  width: 100%;
  background-color: #F6F7F9;
  display: flex;
  flex-direction: column;
}

@media (max-width: 950px) {
  .contentPlaceholder {
    min-height: calc(100vh - 50px - 120px);
    margin-top: 50px;
  }
}

@media (max-width: 750px) {
  .contentPlaceholder {
    min-height: calc(100vh - 50px - 210px);
  }
}

/* General footer styling  */

.footerPlaceholder {
  display: flex;
  flex-direction: row;
  height: 120px;
  width: 100%;
  background-color: #023553;
  color: #ffffff;
}

@media (max-width: 750px) {
  .footerPlaceholder {
    height: 210px;
    flex-direction: column;
    transition: height 0.2s ease;
  }
}

@media (min-width: 750px) {
  .footerPlaceholder {
    transition: height 0.2s ease;
  }
}
